import axios from 'axios';
import dayjs from 'dayjs';
import {message} from "antd";

dayjs.locale('it');

//const PORT = window.location.hostname === 'localhost' ? ':9000' : '';

export const MIN_YEAR = 2020;
export const MAX_YEAR = new Date().getFullYear();

export const INVALID_VALUE = 65536;
export const MIN_HOURS_TICKET = 6;

export const ID_COMMISSION_PERMESSO = 1;
export const ID_COMMISSION_FERIE = 2;
export const ID_COMMISSION_MALATTIA = 3;
export const ID_COMMISSION_ALTRI_PERMESSI_GIORNALIERI = 4;
const ID_COMMISSION_ALTRI_PERMESSI_A_ORE = 5;
//export const ID_COMMISSION_REPERIBILITA_SENZA_INTERVENTO = ; ID da ridefinire
//export const ID_COMMISSION_REPERIBILITA_CON_INTERVENTO = ; ID da ridefinire

export const MAX_PERMESSO_GRAVI_MOTIVI = 3;

export const ID_PLACE_OF_WORK_FUORI_SEDE = 2;

export const CAPABILITY_COMPILE_TIMESHEET = 1;
export const CAPABILITY_ADD_COSTS = 2;
export const CAPABILITY_SEE_TIMESHEETS = 3;
export const CAPABILITY_SEE_STATS = 4;
export const CAPABILITY_MANAGE_COMMISSIONS = 5;
export const CAPABILITY_MANAGE_USERS = 6;
export const CAPABILITY_SEE_HOLIDAYS_REPORT = 7;
export const CAPABILITY_SEE_ACTIVITIES_SUMMARY = 8;

export const COMM_TUTTE = { id: 0, description: 'Tutte le commesse' };
export const SUBCOMM_TUTTE = { id: 0, description: 'Tutte le sottocommesse' };
export const USERS_TUTTI = { id: 0, surname: 'Tutti i dipendenti', name: '' };
export const MESI_TUTTI = { id: 0, name: 'Annuale' };

export const MONTHS_OBJECTS = [{ id: 1, name: 'Gennaio' }, { id: 2, name: 'Febbraio' }, { id: 3, name: 'Marzo' }, { id: 4, name: 'Aprile' }, { id: 5, name: 'Maggio' }, { id: 6, name: 'Giugno' }, { id: 7, name: 'Luglio' }, { id: 8, name: 'Agosto' }, { id: 9, name: 'Settembre' }, { id: 10, name: 'Ottobre' }, { id: 11, name: 'Novembre' }, { id: 12, name: 'Dicembre' }];

export const MESSAGE_TYPE_SUCCESS = 0;
export const MESSAGE_TYPE_WARNING = 1;
export const MESSAGE_TYPE_ERROR = 2;

const WEEKDAYS = ['LUN', 'MAR', 'MER', 'GIO', 'VEN', 'SAB', 'DOM'];

const numberOfDaysInMonth = (year, month) => new Date(year, month, 0).getDate();

const firstWeekdayInMonth = (year, month) => ((new Date(year, (month+11)%12, 1, 0, 0, 0, 0)).getDay() + 6) % 7;

export const getItem = (allItems, id) => {
    if (!allItems || allItems.length === 0) {
        console.error(`[getItem] 'allItems' array is undefined or empty`);
        return null;
    }

    if (id == null) {
        return null;
    }

    const itemsFound = allItems.filter(item => item.id === id);

    if (itemsFound.length === 0) {
        return null;
    }

    if (itemsFound.length > 1) {
        console.warn(`[getItem] More then 1 item with id=${id} found in ${allItems}`);
    }

    return itemsFound[0];
};

export const axiosCall = (method, path, data, logoutCb) => axiosCallWithTo(method, path, data, 10000, logoutCb);

export const axiosCallWithTo = async (method, path, data, timeout, logoutCb) => {
    const options = {
        method: method,
        timeout: timeout,
        withCredentials: true
    };

    switch (method) {
        case 'POST':
        case 'PUT':
        case 'PATCH':
            if (!data) {
                throw new Error(`Empty body request in HTTP method '${method}' call not admitted`);
            }
            options.data = data;
        // No break!
        // eslint-disable-next-line
        case 'GET':
        case 'DELETE':
            break;
        default:
            console.error(`HTTP method '${method}' not permitted`);
            throw new Error(`HTTP method '${method}' not permitted`);
    }

    options.url = `${window.location.protocol}//${window.location.hostname}/timesheet/api${path}`;

    return axios(options)
        .then(ret => ret)
        .catch(e => {
            if (e.response?.data?.indexOf("Login needed") > -1) {
                logoutCb && logoutCb(true);
            } else {
                throw e;
            }
        });
};

export const extractMonthData = (data, allCommissions, allSubcommissions, year, month, userId, holidays, userMaxHours) => {
    const daysInMonth = numberOfDaysInMonth(year, month);
    let dow = firstWeekdayInMonth(year, month);

    const retObj = {
        allDailyStats: [],
        monthHours: 0,
        monthTickets: 0,
        maxHours: data.maxHours.find(el => el.userId === userId)?.maxHours ?? 0,
        leave: 0,
        daysOff: 0
    };

    for (let i = 1; i < daysInMonth + 1; i++) {
        let obj = {
            day: i,
            dayDow: `${i} ${WEEKDAYS[dow]}`,
            rowClassName: holidays[year-MIN_YEAR][month-1][i-1] === 0 ? 'rowHoliday' : 'rowNormal',
            dailyWorkingHours: 0,
            dailyOvertimeHours: 0,
            ticket: null,
            permesso: 0,
            ferie: 0,
            //repNoInt: 0,
            //repSiInt: 0,
            altriPermessiGiornalieri: null,
            altriPermessiAOre: 0,
            malattia: null,
            dailyActivities: [],
            dailyOvertimeActivities: [],
            dailyAltriPermessiGiornalieri: [],
            dailyAltriPermessiAOre: []
        }

        const curDayDs = data.dayByDay.filter(stat => stat.userId === userId && stat.day === i);
        if (curDayDs.length > 0) {
            let ticketsHours = 0;
            let dailyMaxHours = userMaxHours;

            curDayDs.forEach(ds => {
                switch (ds.commissionId) {
                    case ID_COMMISSION_PERMESSO:
                        obj.permesso = ds.hours;
                        retObj.maxHours -= ds.hours;
                        retObj.leave += ds.hours;
                        dailyMaxHours -= ds.hours;
                        break;
                    case ID_COMMISSION_FERIE:
                        obj.ferie = ds.hours;
                        retObj.maxHours -= ds.hours * userMaxHours;
                        retObj.daysOff += ds.hours;
                        dailyMaxHours -= ds.hours * userMaxHours;
                        break;
                    case ID_COMMISSION_MALATTIA:
                        obj.malattia = '✔';
                        if (holidays[year-MIN_YEAR][month-1][i-1] !== 0) {
                            retObj.maxHours -= userMaxHours;
                            dailyMaxHours -= userMaxHours;
                        }
                        break;
                    // case 4:
                    // 	obj.repNoInt = ds.hours;
                    // 	break;
                    // case 5:
                    // 	obj.repSiInt = ds.hours;
                    // 	break;
                    case ID_COMMISSION_ALTRI_PERMESSI_GIORNALIERI:
                        obj.altriPermessiGiornalieri = '✔';
                        retObj.maxHours -= ds.hours * userMaxHours;
                        dailyMaxHours -= ds.hours * userMaxHours;
                        obj.dailyAltriPermessiGiornalieri.push(`${getItem(allSubcommissions, ds.subcommissionId).description}: ${ds.hours.toFixed(1)}`);
                        break;
                    case ID_COMMISSION_ALTRI_PERMESSI_A_ORE:
                        obj.altriPermessiAOre += ds.hours;
                        retObj.maxHours -= ds.hours;
                        dailyMaxHours -= ds.hours;
                        obj.dailyAltriPermessiAOre.push(`${getItem(allSubcommissions, ds.subcommissionId).description}: ${ds.hours.toFixed(1)}`);
                        break;
                    default:
                        obj.dailyWorkingHours += ds.hours;
                        obj.dailyOvertimeHours += ds.overtimeHours;
                        if (ds.placeOfWorkId !== ID_PLACE_OF_WORK_FUORI_SEDE)
                            ticketsHours += ds.hours + ds.overtimeHours;
                        retObj.monthHours += ds.hours;

                        const subcommsOfCurrentComm = allSubcommissions.filter(subcomm => subcomm.commissionId === ds.commissionId);
                        if (ds.hours > 0) {
                            if (subcommsOfCurrentComm.length > 0) {
                                obj.dailyActivities.push(`${getItem(allCommissions, ds.commissionId).description} - ${getItem(allSubcommissions, ds.subcommissionId).description}: ${ds.hours.toFixed(1)}`);
                            } else {
                                obj.dailyActivities.push(`${getItem(allCommissions, ds.commissionId).description}: ${ds.hours.toFixed(1)}`);
                            }
                        }
                        if (ds.overtimeHours > 0) {
                            if (subcommsOfCurrentComm.length > 0) {
                                obj.dailyOvertimeActivities.push(`${getItem(allCommissions, ds.commissionId).description} - ${getItem(allSubcommissions, ds.subcommissionId).description}: ${ds.hours.toFixed(1)}`);
                            } else {
                                obj.dailyOvertimeActivities.push(`${getItem(allCommissions, ds.commissionId).description}: ${ds.hours.toFixed(1)}`);
                            }
                        }
                }
            });

            if (ticketsHours >= MIN_HOURS_TICKET) {
                obj.ticket = '✔';
                retObj.monthTickets++;
            }

            if (holidays[year-MIN_YEAR][month-1][i-1] !== 0) {
                if (obj.dailyWorkingHours + obj.dailyOvertimeHours > dailyMaxHours) {
                    obj.rowClassName = 'rowCompiledSuperOk';
                } else if (obj.dailyWorkingHours + obj.dailyOvertimeHours === dailyMaxHours) {
                    obj.rowClassName = 'rowCompiledOk';
                } else {
                    obj.rowClassName = 'rowCompiledWarning';
                }
            }
        }

        retObj.allDailyStats.push(obj);
        dow = ++dow % 7;
    }

    return retObj;
};

export const extractDataForExport = (data, allCommissions, allSubcommissions, allUsers, year, month) => {
    const daysInMonth = numberOfDaysInMonth(year, month);
    const firstWeekday = firstWeekdayInMonth(year, month);
    const retObj = [];

    allUsers.forEach(user => {
        const userObj = {
            completeName: `${user.surname} ${user.name}`,
            leave: 0,
            daysOff: 0,
            daysOffDates: '',
            permessiGiornalieri: new Map(),
            permessiAOre: new Map(),
            overtimeHours: 0,
            malattia: 0
        };

        let dow = firstWeekday;

        for (let i = 1; i < daysInMonth + 1; i++) {
            const curDayDs = data.dayByDay.filter(stat => stat.userId === user.id && stat.day === i);
            if (curDayDs.length > 0) {
                for (const ds of curDayDs) {
                    switch (ds.commissionId) {
                        case ID_COMMISSION_PERMESSO:
                            userObj.leave += ds.hours;
                            break;
                        case ID_COMMISSION_FERIE:
                            userObj.daysOff += ds.hours;
                            userObj.daysOffDates += buildDayOffDateStr(userObj.daysOffDates === '', dow, ds.day, ds.hours === 0.5);
                            break;
                        case ID_COMMISSION_MALATTIA:
                            userObj.malattia += ds.hours;
                            break;
                        case ID_COMMISSION_ALTRI_PERMESSI_GIORNALIERI:
                            const descPG = getItem(allSubcommissions, ds.subcommissionId).description;
                            let pg;
                            if (userObj.permessiGiornalieri.has(descPG)) {
                                pg = userObj.permessiGiornalieri.get(descPG);
                                pg.hours += ds.hours;
                                pg.days.push(ds.day)
                            } else {
                                pg = {
                                    hours: ds.hours,
                                    days: [ds.day]
                                };
                            }
                            userObj.permessiGiornalieri.set(descPG, pg);
                            break;
                        case ID_COMMISSION_ALTRI_PERMESSI_A_ORE:
                            const descPAO = getItem(allSubcommissions, ds.subcommissionId).description;
                            let pao;
                            if (userObj.permessiAOre.has(descPAO)) {
                                pao = userObj.permessiAOre.get(descPAO);
                                pao.hours += ds.hours;
                                pao.days.push(ds.day)
                            } else {
                                pao = {
                                    hours: ds.hours,
                                    days: [ds.day]
                                };
                            }
                            userObj.permessiAOre.set(descPAO, pao);
                            break;
                        default:
                            userObj.overtimeHours += ds.overtimeHours;
                    }
                }
            }
            dow = ++dow % 7;
        }

        retObj.push(userObj);
    });

    return retObj;
};

const buildDayOffDateStr = (firstDate, dow, day, halfDay) => {
    let ret = firstDate ? '' : ', ';
    ret += `${WEEKDAYS[dow]} ${day}`;
    if (halfDay) {
        ret += ' ◐';
    }
    return ret;
};

export const extractDataForTickets = (data, allCommissions, allSubcommissions, allUsers, year, month) => {
    const daysInMonth = numberOfDaysInMonth(year, month);
    const retObj = [];

    allUsers.forEach(user => {
        const userObj = {
            completeName: `${user.surname} ${user.name}`,
            tickets: 0
        };

        for (let i = 1; i < daysInMonth + 1; i++) {
            const curDayDs = data.dayByDay.filter(stat => stat.userId === user.id && stat.day === i);
            if (curDayDs.length > 0) {
                let ticketsHours = 0;

                curDayDs.forEach(ds => {
                    if (ds.commissionId > ID_COMMISSION_ALTRI_PERMESSI_A_ORE && ds.placeOfWorkId !== ID_PLACE_OF_WORK_FUORI_SEDE) {
                        ticketsHours += ds.hours + ds.overtimeHours;
                    }
                });

                if (ticketsHours >= MIN_HOURS_TICKET) {
                    userObj.tickets++;
                }
            }
        }

        retObj.push(userObj);
    });

    return retObj;
};

export const showToast = (text, msgType, error) => {
    switch (msgType) {
        case MESSAGE_TYPE_ERROR:
            if (error) {
                console.error(text, error);
            }
            message.error(text, 3);
            break;
        case MESSAGE_TYPE_WARNING:
            message.warning(text, 3);
            break;
        default:
            message.success(text, 3);
    }
};

export const getCompleteName = (allUsers, userId) => {
    const user = getItem(allUsers, userId);
    if (user) {
        return `${user.surname} ${user.name}`;
    } else {
        return null;
    }
};