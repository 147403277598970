import {useState} from 'react';
import {Button, Collapse, Form, Input} from 'antd';
import Reaptcha from 'reaptcha';

const Login = ({
	changeUsername = null,
	changePassword = null,
	changeNewPassword = null,
	login = null,
	onVerifyRecaptcha = null,
	onErrorRecaptcha = null,
	recaptchaSiteKey = ''
}) => {
	const [username, setUsername] = useState('');
	const [password, setPassword] = useState('');
	const [newPassword1, setNewPassword1] = useState('');
	const [newPassword2, setNewPassword2] = useState('');

	const onUsernameChange = e => {
		setUsername(e.target.value);
		changeUsername(e.target.value);
	};

	const onPasswordChange = e => {
		setPassword(e.target.value);
		changePassword(e.target.value);
	};

	const onNewPasswordChange1 = e => {
		setNewPassword1(e.target.value);
		changeNewPassword(e.target.value);
	};

	const onNewPasswordChange2 = e => {
		setNewPassword2(e.target.value);
	};

	const renderItems = () => {
		const children = [
			<Input
				key={'panel1'}
				style={{marginTop: 10}}
				placeholder='Nuova password'
				type='password'
				onChange={onNewPasswordChange1}
				allowClear
				autoComplete='new-password'
			/>,
			<Input
				key={'panel2'}
				style={{marginTop: 30}}
				placeholder='Ripeti nuova password'
				type='password'
				onChange={onNewPasswordChange2}
				allowClear
				autoComplete='new-password'
			/>
		];

		if (newPassword1 !== '' && newPassword2 !== '' && newPassword1 !== newPassword2) {
			children.push(<div key={'panel3'} style={{ color: 'red' }}>Le nuove password non coincidono</div>);
		}

		children.push(<Button
			key={'panel4'}
			style={{marginTop: 30}}
			disabled={username === '' || password === '' || newPassword1 === '' || newPassword1 !== newPassword2}
			onClick={() => login(captcha, true)}
			danger
		>
			Cambia password
		</Button>);

		return [
			{
				key: '1',
				label: 'Cambia password',
				children: children,
			}
		];
	}

	let captcha;

	return (
		<div style={{ margin: '100px auto', width: '40%', maxWidth: 480 }}>
			<Form
				name='loginForm'
			>
				<Form.Item
					name='username'
					rules={[{ required: true, message: 'Username obbligatorio' }]}
				>
					<Input
						placeholder='Username'
						onChange={onUsernameChange}
						allowClear
						autoComplete='username'
					/>
				</Form.Item>

				<Form.Item
					name='password'
					rules={[{ required: true, message: 'Password obbligatoria' }]}
				>
					<Input.Password
						placeholder='Password'
						type='password'
						onChange={onPasswordChange}
						allowClear
						autoComplete='current-password'
					/>
				</Form.Item>

				<Form.Item
				>
					<Button
						type='primary'
						disabled={username === '' || password === ''}
						onClick={() => login(captcha, false)}
					>
						LOGIN
					</Button>
				</Form.Item>
			</Form>

			<Collapse style={{ marginTop: 80 }} items={renderItems()} />

			{
				recaptchaSiteKey &&
				<Reaptcha
					ref={e => captcha = e}
					size='invisible'
					sitekey={recaptchaSiteKey}
					onVerify={recaptchaResponse => onVerifyRecaptcha(captcha, recaptchaResponse)}
					onError={onErrorRecaptcha}
				/>
			}
		</div>
	);
};

export default Login;