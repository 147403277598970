import {createContext, useEffect, useRef, useState} from 'react';
import {Button, Spin, Tabs, Tag} from 'antd';
import shajs from 'sha.js';

import 'dayjs/locale/it';

import {
	axiosCall,
	axiosCallWithTo,
	CAPABILITY_ADD_COSTS,
	CAPABILITY_COMPILE_TIMESHEET,
	CAPABILITY_MANAGE_COMMISSIONS,
	CAPABILITY_MANAGE_USERS,
	CAPABILITY_SEE_ACTIVITIES_SUMMARY,
	CAPABILITY_SEE_HOLIDAYS_REPORT,
	CAPABILITY_SEE_STATS,
	CAPABILITY_SEE_TIMESHEETS,
	getItem,
	MESSAGE_TYPE_ERROR,
	MESSAGE_TYPE_WARNING,
	showToast
} from '../utilities';

import Login from './Login';
import MyTimesheet from './MyTimesheet';
import AllTimesheets from './AllTimesheets';
import CommissionsStats from './CommissionsStats';
import ManageCommissions from './ManageCommissions';
import ManageUsers from './ManageUsers';
import DaysOffAndLeaveReport from "./DaysOffAndLeaveReport";
import Costs from "./Costs";
import ActivitiesSummary from "./ActivitiesSummary";

export const GlobalDataContext = createContext({});

const App = () => {
	const [initPage, setInitPage] = useState(true);
	const [username, setUsername] = useState('');
	const [password, setPassword] = useState('');
	const [doSetNewPassword, setDoSetNewPassword] = useState(false);
	const [newPassword, setNewPassword] = useState('');
	const [secret, setSecret] = useState('');
	const [completeName, setCompleteName] = useState('');
	const [loggedIn, setLoggedIn] = useState(false);
	const [loggedUserId, setLoggedUserId] = useState(0);
	const [userCapabilities, setUserCapabilities] = useState([]);
	const [allCommissions, setAllCommissions] = useState([]);
	const [allSubcommissions, setAllSubcommissions] = useState([]);
	const [allPlacesOfWork, setAllPlacesOfWork] = useState([]);
	const [allUsers, setAllUsers] = useState([]);
	const [usersPermGraviMotiviTot, setUsersPermGraviMotiviTot] = useState(null);
	const [loading, setLoading] = useState(0);
	const [recaptchaSiteKey, setRecaptchaSiteKey] = useState('');
	const [holidays, setHolidays] = useState(null);
	const [permGraviMotiviSubcommId, setPermGraviMotiviSubcommId] = useState(null);
	const [intervalId, setIntervalId] = useState(0);

	const L_LOGIN = 1;
	const L_COMMISSIONS = 2;
	const L_SUBCOMMISSIONS = 4;
	const L_PLACESOFWORK = 8;
	const L_USERS = 16;
	const L_USERSPERMGRAVIMOTIVITOT = 32;

	const setLoadingField = field => setLoading(loading | field);
	const unsetLoadingField = field => setLoading(loading & ~field);

	const intervalIdRef = useRef();
	intervalIdRef.current = intervalId;

	const checkServer = async () => {
		try {
			let resp = await axiosCallWithTo('GET', '/config', null, 3000, null);

			const beVersion = resp.data.beVersion;

			setRecaptchaSiteKey(resp.data.recaptchaSiteKey);

			resp = await axiosCallWithTo('GET', '/holidays', null, 3000, null);

			setHolidays(resp.data);

			console.log(`Versione Timesheet BE: ${beVersion}`)
		} catch (e) {
			showToast('Il server non risponde', MESSAGE_TYPE_ERROR, null);
		}
	};

	const getCommissions = async () => {
		setLoadingField(L_COMMISSIONS);

		try {
			const response = await axiosCall('GET', '/commissions', null, logout);
			setAllCommissions(response.data/*.filter(comm => comm.id !== ID_COMMISSION_REPERIBILITA_SENZA_INTERVENTO && comm.id !== ID_COMMISSION_REPERIBILITA_CON_INTERVENTO)*/ ?? []);
		} catch (e) {
			setAllCommissions([]);
		}

		unsetLoadingField(L_COMMISSIONS);
	};

	const getSubcommissions = async () => {
		setLoadingField(L_SUBCOMMISSIONS);

		try {
			const response = await axiosCall('GET', '/subcommissions', null, logout);
			setAllSubcommissions(response.data ?? []);

			if (response.data) {
				const permGraviMotiviSubcomm =  response.data.find(subcomm => subcomm.description === 'Permesso retribuito per gravi motivi');
				if (permGraviMotiviSubcomm) {
					setPermGraviMotiviSubcommId(permGraviMotiviSubcomm.id);
				}
			}
		} catch (e) {
			setAllSubcommissions([]);
		}

		unsetLoadingField(L_SUBCOMMISSIONS);
	};

	const getPlacesOfWork = async () => {
		setLoadingField(L_PLACESOFWORK);

		try {
			const response = await axiosCall('GET', '/placesofwork', null, logout);
			setAllPlacesOfWork(response.data ?? []);
		} catch (e) {
			setAllPlacesOfWork([]);
		}

		unsetLoadingField(L_PLACESOFWORK);
	};

	const getUsers = async () => {
		setLoadingField(L_USERS);

		try {
			const response = await axiosCall('GET', '/users', null, logout);
			setAllUsers(response.data ?? []);
		} catch (e) {
			setAllUsers([]);
		}

		unsetLoadingField(L_USERS);
	};

	const getUsersPermGraviMotiviTot = async () => {
		setLoadingField(L_USERSPERMGRAVIMOTIVITOT);

		try {
			const response = await axiosCall('GET', '/usersPermGraviMotiviTot', null, logout);
			setUsersPermGraviMotiviTot(response.data);
		} catch (e) {
			setUsersPermGraviMotiviTot(null);
		}

		unsetLoadingField(L_USERSPERMGRAVIMOTIVITOT);
	};

	const loginToken = () => document.cookie.split(' ').find(c => c.startsWith('token='));

	useEffect(() => {
		checkServer();

		const tokenCookie = loginToken();
		if (tokenCookie) {
			let username;
			let secret;
			let loggedUserId;
			let completeName;
			let userCapabilities;

			const tokenData = tokenCookie.substring(6).split('&');
			tokenData.forEach(d => {
				const keyValue = d.split('=');

				// eslint-disable-next-line
				switch (keyValue[0]) {
					case 'username':
						username = keyValue[1];
						break;
					case 'secret':
						secret = keyValue[1];
						break;
					case 'userId':
						loggedUserId = keyValue[1];
						break;
					case 'completeName':
						completeName = keyValue[1];
						break;
					case 'userCapabilities':
						userCapabilities = JSON.parse(keyValue[1]);
						break;
				}
			});

			if (username !== '' && secret !== '' && loggedUserId && completeName !== '' && userCapabilities) {
				setUsername(username);
				setSecret(secret);
				setLoggedUserId(loggedUserId);
				setCompleteName(completeName);
				setUserCapabilities(userCapabilities);
				setLoggedIn(true);

				startPing();
			} else {
				deleteUserCookie();
			}
		} else {
			deleteUserCookie();
		}

		setInitPage(false);
		// eslint-disable-next-line
	}, []);

	useEffect(() => {
		if (username && secret) {
			getCommissions();
			getSubcommissions();
			getPlacesOfWork();
			getUsers();
			getUsersPermGraviMotiviTot();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [username, secret]);

	const login = (captchaObj, doSetNewPassword) => {
		setLoadingField(L_LOGIN);
		setDoSetNewPassword(doSetNewPassword);
		try {
			captchaObj.execute();
		} catch (e) {
			unsetLoadingField(L_LOGIN);
			showToast('Errore recaptcha', MESSAGE_TYPE_ERROR, null);
		}
	};

	const onVerifyRecaptcha = async (captchaObj, recaptchaResponse) => {
		const hash = shajs('sha256').update(`${username}:${password}`).digest('hex');

		try {
			const loginData = {};
			loginData.recaptchaResponse = recaptchaResponse;

			let curSecret;

			if (doSetNewPassword && newPassword) {
				curSecret = shajs('sha256').update(`${username}:${newPassword}`).digest('hex');
				loginData.newSecret = curSecret;
			} else {
				curSecret = hash;
			}

			writeUserCookie(username, hash);

			const response = await axiosCall('POST', '/login', loginData, logout);

			if (response.data.capabilities.length > 0) {
				writeUserCookie(username, curSecret, response.data.id, response.data.completeName, response.data.capabilities);

				setSecret(curSecret);
				setLoggedUserId(response.data.id);
				setCompleteName(response.data.completeName);
				setUserCapabilities(JSON.parse(response.data.capabilities));
				setPassword('');
				setNewPassword('');
				setLoggedIn(true);

				startPing();
			} else {
				deleteUserCookie();
			}
		} catch(e) {
			captchaObj.reset();
			deleteUserCookie();
			if (e.response && e.response.status === 401)
				showToast('Credenziali errate', MESSAGE_TYPE_ERROR, null);
			else
				showToast('Errore di comunicazione col server', MESSAGE_TYPE_ERROR, e);
		}

		unsetLoadingField(L_LOGIN);
	};

	const onErrorRecaptcha = () => {
		unsetLoadingField(L_LOGIN);
		showToast('Errore recaptcha', MESSAGE_TYPE_ERROR, null);
	};

	const logout = (sessionExpired) => {
		if (intervalIdRef.current > 0) {
			clearInterval(intervalIdRef.current);
			setIntervalId(0);
		}

		deleteUserCookie();

		setUsername('');
		setSecret('');
		setLoggedIn(false);
		setLoggedUserId(0);
		setCompleteName('');
		setUserCapabilities([]);
		setAllCommissions([]);
		setAllUsers([]);

		if (sessionExpired) {
			showToast('Sessione scaduta. Effettua nuovamente il login', MESSAGE_TYPE_WARNING, null);
		}
	};

	const showTab = capability => userCapabilities.filter(cap => cap === capability).length > 0;

	const onTabChange = (activeTab) => {
		if (!loginToken()) {
			logout(true);
		}

		sessionStorage.setItem('selectedTab', activeTab);
	};

	const writeUserCookie = (username = '', secret = '', loggedUserId, completeName, userCapabilities) => {
		let value = `username=${username}&secret=${secret}`;

		if (loggedUserId) {
			value += `&userId=${loggedUserId}`;
		}

		if (completeName) {
			value += `&completeName=${completeName}`;
		}

		if (userCapabilities) {
			value += `&userCapabilities=${userCapabilities}`;
		}

		document.cookie = `token=${value};max-age=1800;path=/;Secure;SameSite=Lax`
	};

	const deleteUserCookie = () => document.cookie = `token=;max-age=0;path=/;Secure;SameSite=Lax`;

	const selectedTab = sessionStorage.getItem('selectedTab') ?? '1';

	const ping = async () => {
		try {
			await axiosCallWithTo("GET", "/ping", null, 3000, logout);
		} catch (e) {}
	};

	const startPing = () => {
		if (intervalIdRef.current === 0) {
			setIntervalId(setInterval(ping, 10000));
		}
	};

	const globalDataContext = {
		holidays,
		allCommissions,
		allSubcommissions,
		allPlacesOfWork,
		allUsers,
		usersPermGraviMotiviTot,
		permGraviMotiviSubcommId,
		getUsersPermGraviMotiviTot,
		logout,
		loggedUserId
	};

	const getTabs = () => {
		const tabs = [];

		if (showTab(CAPABILITY_COMPILE_TIMESHEET)) {
			tabs.push({
				label: 'Il tuo timesheet',
				key: CAPABILITY_COMPILE_TIMESHEET,
				children: <MyTimesheet
					userMaxHours={getItem(allUsers, loggedUserId).workingHours}
					myTabKey={CAPABILITY_COMPILE_TIMESHEET}
					selectedTab={parseInt(selectedTab, 10)}
				/>
			});
		}

		if (showTab(CAPABILITY_SEE_ACTIVITIES_SUMMARY)) {
			tabs.push({
				label: 'Le tue attività',
				key: CAPABILITY_SEE_ACTIVITIES_SUMMARY,
				children: <ActivitiesSummary />
			});
		}

		if (showTab(CAPABILITY_ADD_COSTS)) {
			tabs.push({
				label: 'Le tue spese',
				key: CAPABILITY_ADD_COSTS,
				children: <Costs/>
			});
		}

		if (showTab(CAPABILITY_SEE_TIMESHEETS)) {
			tabs.push({
				label: 'I timesheet di tutti',
				key: CAPABILITY_SEE_TIMESHEETS,
				children: <AllTimesheets
					myTabKey={CAPABILITY_SEE_TIMESHEETS}
					selectedTab={parseInt(selectedTab, 10)}
				/>
			});
		}

		if (showTab(CAPABILITY_SEE_STATS)) {
			tabs.push({
				label: 'Statistiche commesse',
				key: CAPABILITY_SEE_STATS,
				children: <CommissionsStats/>
			});
		}

		if (showTab(CAPABILITY_MANAGE_COMMISSIONS)) {
			tabs.push({
				label: 'Gestione commesse',
				key: CAPABILITY_MANAGE_COMMISSIONS,
				children: <ManageCommissions
					reloadCommissions={getCommissions}
					reloadSubcommissions={getSubcommissions}
				/>
			});
		}

		if (showTab(CAPABILITY_MANAGE_USERS)) {
			tabs.push({
				label: 'Gestione utenti',
				key: CAPABILITY_MANAGE_USERS,
				children: <ManageUsers reloadUsers={getUsers} />
			});
		}

		if (showTab(CAPABILITY_SEE_HOLIDAYS_REPORT)) {
			tabs.push({
				label: 'Prospetto ferie/permessi',
				key: CAPABILITY_SEE_HOLIDAYS_REPORT,
				children: <DaysOffAndLeaveReport/>
			});
		}

		return tabs;
	};

	return (
		<div>
			<GlobalDataContext.Provider value={globalDataContext}>
				<div style={{display: "flex", justifyContent: "space-between", padding: "8px 24px", border: "1px solid #EBEDF0"}}>
					<div style={{display: "flex", alignItems: "center", gap: 16}}>
						<img style={{width: 80, height: 48, objectFit: "cover"}} src="https://www.spxlab.com/wp-content/uploads/2022/10/logo.png" alt={'logo'} />
						<span style={{fontSize: 20, fontWeight: 600}}>TIMESHEET</span>
					</div>
					{
						loggedIn &&
						<div style={{display: "flex", alignItems: "center", gap: 16}}>
							<Tag>{completeName}</Tag>
							<Button onClick={() => logout(false)}>LOGOUT</Button>
						</div>
					}
				</div>
				<Spin spinning={!!loading} size='large'>
				{
					(!initPage && (!loggedIn || (allCommissions.length > 0 && allPlacesOfWork.length > 0 && allUsers.length > 0))) && (
						loggedIn ?
						<Tabs type='card' defaultActiveKey={selectedTab} onChange={onTabChange} items={getTabs()} style={{marginTop: 16}} /> :
						<Login
							changeUsername={(val => setUsername(val))}
							changePassword={val => setPassword(val)}
							changeNewPassword={val => setNewPassword(val)}
							login={login}
							recaptchaSiteKey={recaptchaSiteKey}
							onVerifyRecaptcha={onVerifyRecaptcha}
							onErrorRecaptcha={onErrorRecaptcha}
						/>
					)
				}
				</Spin>
			</GlobalDataContext.Provider>
		</div>
	);
};

export default App;