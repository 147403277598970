import {useContext, useEffect, useState} from "react";
import {GlobalDataContext} from "./App";
import {Col, ConfigProvider, InputNumber, Row, Select, Spin, Table} from "antd";
import {axiosCall, MAX_YEAR, MESI_TUTTI, MIN_YEAR, MONTHS_OBJECTS} from "../utilities";

const {Option} = Select;

const ActivitiesSummary = () => {
    const allMonths = [MESI_TUTTI].concat(MONTHS_OBJECTS);

    const globalDataContext = useContext(GlobalDataContext);

    const [selectedMonth, setSelectedMonth] = useState(allMonths[0]);
    const [selectedYear, setSelectedYear] = useState(new Date().getFullYear());
    const [loading, setLoading] = useState(false);
    const [activitiesSummary, setActivitiesSummary] = useState([]);

    useEffect(() => {
        getData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedMonth, selectedYear]);

    const getData = async () => {
        try {
            setLoading(true);

            const response = await axiosCall('GET', `/activitiessummary/${selectedYear}/${selectedMonth.id}`, null, globalDataContext.logout);

            setActivitiesSummary(response.data);
        } catch (e) {
            setActivitiesSummary([]);
        } finally {
            setLoading(false);
        }
    };

    const EmptyTable = () => (
        <div style={{ textAlign: 'center' }}>
            <p>Non esistono dati corrispondenti ai parametri di ricerca</p>
        </div>
    );

    return (
        <div style={{ padding: '0 20px' }}>
            <Spin spinning={loading} size="large">
                <Row gutter={8} style={{ marginBottom: 30 }}>

                    <Col span={2}>
                        <InputNumber
                            style={{ width: '100%' }}
                            min={MIN_YEAR}
                            max={MAX_YEAR}
                            defaultValue={MAX_YEAR}
                            type={'number'}
                            onChange={setSelectedYear}
                        />
                    </Col>

                    <Col span={3}>
                        <Select
                            style={{ width: '100%' }}
                            listHeight={416}
                            onChange={(value, option) => setSelectedMonth(allMonths[option.idx])}
                            value={selectedMonth.name}
                        >
                            {
                                allMonths.map((month, idx) =>
                                    <Option
                                        key={'m' + idx}
                                        value={month.name}
                                        idx={idx}
                                    >
                                        {month.name}
                                    </Option>
                                )
                            }
                        </Select>
                    </Col>

                </Row>

                <Row>
                    <ConfigProvider renderEmpty={EmptyTable}>
                        <Table
                            size={'small'}
                            rowKey={summary => summary.commission}
                            columns={[
                                {
                                    title: 'Commessa - sottocommessa',
                                    dataIndex: 'commission'
                                },
                                {
                                    title: 'Ore totali',
                                    dataIndex: 'hours',
                                    align: 'center',
                                    render: text => text.toFixed(1)
                                }
                            ]}
                            dataSource={activitiesSummary}
                            pagination={false}
                        />
                    </ConfigProvider>
                </Row>
            </Spin>
        </div>
    );
}

export default ActivitiesSummary;