import {useContext, useState} from 'react';
import {Button, Col, Form, Input, InputNumber, Modal, Row, Select, Tooltip} from 'antd';
import {DeleteOutlined, SaveOutlined} from '@ant-design/icons';

import {
    getItem,
    //ID_COMMISSION_REPERIBILITA_SENZA_INTERVENTO,
    //ID_COMMISSION_REPERIBILITA_CON_INTERVENTO,
    ID_COMMISSION_ALTRI_PERMESSI_GIORNALIERI,
    ID_COMMISSION_MALATTIA,
    MAX_PERMESSO_GRAVI_MOTIVI
} from '../utilities';
import {GlobalDataContext} from "./App";

const {Option, OptGroup} = Select;

const Activity = ({
    id = 0,
    hours = 0.0,
    overtimeHours = 0,
    commission = undefined,
    subcommission = undefined,
    placeOfWorkId = undefined,
    notes = '',
    currentDateActivities = [],
    onSelectCommission = null,
    onSelectSubcommission = null,
    onSelectPlaceOfWork = null,
    onModifyHours = null,
    onModifyOvertimeHours = null,
    onModifyNotes = null,
    onSaveActivity = null,
    onDeleteActivity = null
}) => {
    const globalDataContext = useContext(GlobalDataContext);

    const [showNotesModal, setShowNotesModal] = useState(false);

    const closeNotesModal = save => {
        setShowNotesModal(false);
        if (save && onSaveActivity) {
            onSaveActivity(id);
        }
    };

    const filterCommissions = comm => {
        const subcommOfComm = globalDataContext.allSubcommissions.filter(subcomm => subcomm.commissionId === comm.id);
        const currentCommInstancesNumber = currentDateActivities.filter(act => act.commissionId === comm.id).length;

        if (subcommOfComm.length === 0) {
            return currentCommInstancesNumber < globalDataContext.allPlacesOfWork.length;
        } else {
            return currentCommInstancesNumber < (subcommOfComm.length * globalDataContext.allPlacesOfWork.length);
        }
    };

    const filterSubcommissions = subcomm => {
        const currentSubcommInstancesNumber = currentDateActivities.filter(act => act.subcommissionId === subcomm.id).length;

        return currentSubcommInstancesNumber < globalDataContext.allPlacesOfWork.length;
    };

    const filterPlacesOfWork = placeOfWork => {
        const subcommOfComm = globalDataContext.allSubcommissions.filter(subcomm => subcomm.commissionId === commission?.id);

        if (subcommOfComm.length === 0) {
            const currentCommInstances = currentDateActivities.filter(act => act.commissionId === commission?.id);
            return currentCommInstances.filter(act => act.placeOfWorkId === placeOfWork.id).length === 0;
        } else {
            const currentSubcommInstances = currentDateActivities.filter(act => act.subcommissionId === subcommission?.id);
            return currentSubcommInstances.filter(act => act.placeOfWorkId === placeOfWork.id).length === 0;
        }
    };

    const sortCommissions = (a, b) => {
        // Ordino i primi 5 elementi per id
        if (a.id < 6 && b.id < 6) {
            return a.id - b.id;
        }

        // Gestisco l'ordinamento 5°/6° elemento, in modo che il 5° venga messo sempre prima del 6°
        if (a.id < 6) {
            return -1;
        }

        if (b.id < 6) {
            return 1;
        }

        // Dal 6° elemento in poi non modifico l'ordine (mi arrivano in ordine alfabetico dal backend)
        return 0;
    }

    const renderCommissions = lsComm => {
        if (lsComm) {
            return (
                <>
                    <OptGroup label='Ultima commessa selezionata'>
                        <Option
                            key={'lsc'}
                            value={lsComm.description}
                            activityid={id}
                            commissionid={lsComm.id}
                        >
                            {lsComm.description}
                        </Option>
                    </OptGroup>
                    <OptGroup label='Altre commesse'>
                        {
                            globalDataContext.allCommissions.filter(comm => comm.id !== lsComm.id && comm.active).filter(filterCommissions).sort(sortCommissions).map((comm, idx) =>
                                <Option
                                    key={'c' + idx}
                                    value={comm.description}
                                    activityid={id}
                                    commissionid={comm.id}
                                >
                                    {comm.description}
                                </Option>
                            )
                        }
                    </OptGroup>
                </>
            )
        } else {
            return (
                <>
                    {
                        globalDataContext.allCommissions.filter(comm => comm.active).filter(filterCommissions).sort(sortCommissions).map((comm, idx) =>
                            <Option
                                key={'c' + idx}
                                value={comm.description}
                                activityid={id}
                                commissionid={comm.id}
                            >
                                {comm.description}
                            </Option>
                        )
                    }
                </>
            )
        }
    };

    const renderSubcommissions = (lsSubcomm, subcommsOfCurrentComm) => {
        if (lsSubcomm) {
            return (
                <>
                    <OptGroup label='Ultima sottocommessa selezionata'>
                        <Option
                            key={'lss'}
                            value={lsSubcomm.description}
                            activityid={id}
                            subcommissionid={lsSubcomm.id}
                        >
                            {lsSubcomm.description}
                        </Option>
                    </OptGroup>
                    <OptGroup label='Altre sottocommesse'>
                        {
                            subcommsOfCurrentComm
                                .filter(subcomm => subcomm.id !== lsSubcomm.id && subcomm.active)
                                .filter(filterSubcommissions)
                                .map((subcomm, idx) =>
                                    <Option
                                        key={'s' + idx}
                                        value={subcomm.description}
                                        activityid={id}
                                        subcommissionid={subcomm.id}
                                        disabled={!subcomm.active}
                                    >
                                        {subcomm.description}
                                    </Option>
                            )
                        }
                    </OptGroup>
                </>
            )
        } else {
            return (
                <>
                    {
                        subcommsOfCurrentComm
                            .filter(subcomm => subcomm.active)
                            .filter(subcomm => subcomm.id !== globalDataContext.permGraviMotiviSubcommId || (globalDataContext.usersPermGraviMotiviTot[globalDataContext.loggedUserId] ?? 0) < MAX_PERMESSO_GRAVI_MOTIVI)
                            .filter(filterSubcommissions)
                            .map((subcomm, idx) =>
                                <Option
                                    key={'s' + idx}
                                    value={subcomm.description}
                                    activityid={id}
                                    subcommissionid={subcomm.id}
                                    disabled={!subcomm.active}
                                >
                                    {subcomm.description}
                                </Option>
                        )
                    }
                </>
            )
        }
    };

    const subcommsOfCurrentComm = globalDataContext.allSubcommissions.filter(subcomm => subcomm.commissionId === commission?.id);

    const lsStringData = localStorage.getItem('lastSavedActivity');
    const lsData = lsStringData ? JSON.parse(lsStringData) : [];
    let lsComm;
    let lsSubcomm;
    if (lsData.length > 0) {
        const lsObj = lsData.find(el => el.userId === globalDataContext.loggedUserId);
        if (lsObj) {
            lsComm = getItem(globalDataContext.allCommissions.filter(comm => comm.active).filter(filterCommissions), lsObj.commissionId);
            if (lsComm && subcommsOfCurrentComm.length > 0) {
                const filteredSubcommsOfCurrentComm = subcommsOfCurrentComm.filter(subcomm => subcomm.active).filter(filterSubcommissions);
                if (filteredSubcommsOfCurrentComm.length > 0 && lsObj.subcommissionId) {
                    lsSubcomm = getItem(filteredSubcommsOfCurrentComm, lsObj.subcommissionId);
                }
            }
        }
    }

    const containerDivStyle = {
        marginBottom: '10px',
        border: '2px solid #40a9ff',
        borderRadius: '5px',
        padding: '5px 5px 0'
    };
    if (commission && !commission.active) {
        containerDivStyle.opacity = 0.6;
        containerDivStyle.pointerEvents = 'none';
    }

    const renderNotes = () => notes ? notes : (id ? '' : "Per poter salvare le note devi prima salvare l'attività");

    const saveAndNotesButtonsDisabled = () => !commission || (!subcommission && subcommsOfCurrentComm.length > 0) || (!placeOfWorkId && commission.type === 2) || (hours === 0 && overtimeHours === 0) || (subcommission && !subcommission.active);

    return (
        <div style={containerDivStyle}>
            <Row gutter={8}>
                <Col span={9}>
                    <Row style={{ marginBottom: 5 }}>
                        <Select
                            style={{ width: '100%' }}
                            showSearch
                            placeholder={'Scegli una commessa'}
                            onChange={onSelectCommission}
                            value={commission?.description}
                        >
                            {
                                renderCommissions(lsComm)
                            }
                        </Select>
                    </Row>
                    <Row>
                        <Form.Item
                            label={commission?.type === 0 ? 'Ore' : (commission?.type === 1 ? 'Giorno' : 'Ore ordinarie')}
                            style={{ marginBottom: 0 }}
                        >
                            <InputNumber
                                min={/*commissionId === ID_COMMISSION_REPERIBILITA_SENZA_INTERVENTO || commissionId === ID_COMMISSION_REPERIBILITA_CON_INTERVENTO ? 1 :*/ 0.0}
                                max={commission?.type === 1 ? 1 : 20}
                                step={/*commissionId === ID_COMMISSION_REPERIBILITA_SENZA_INTERVENTO || commissionId === ID_COMMISSION_REPERIBILITA_CON_INTERVENTO ? 1 :*/ 0.5}
                                disabled={!commission || commission.id === ID_COMMISSION_MALATTIA || commission.id === ID_COMMISSION_ALTRI_PERMESSI_GIORNALIERI || (subcommission && !subcommission.active)}
                                value={hours}
                                precision={1}
                                formatter={val => (+val).toFixed(1)}
                                type={'number'}
                                onChange={val => onModifyHours(id, val)}
                            />
                        </Form.Item>
                    </Row>
                </Col>

                <Col span={9}>
                    <Row style={{ marginBottom: 5 }}>
                        <Select
                            style={{ width: '100%' }}
                            showSearch
                            disabled={!commission || subcommsOfCurrentComm.length === 0}
                            placeholder={'Scegli una sottocommessa'}
                            onChange={onSelectSubcommission}
                            value={subcommission?.description}
                        >
                            {
                                renderSubcommissions(lsSubcomm, subcommsOfCurrentComm)
                            }
                        </Select>
                    </Row>
                    <Row>
                        <Form.Item
                            label='Ore straordinario'
                            style={{ marginBottom: 0 }}
                        >
                            <InputNumber
                                min={0}
                                max={20}
                                step={0.5}
                                disabled={!commission || commission.type < 2 || (subcommission && !subcommission.active)}
                                value={overtimeHours}
                                precision={1}
                                formatter={val => (+val).toFixed(1)}
                                type={'number'}
                                onChange={val => onModifyOvertimeHours(id, val)}
                            />
                        </Form.Item>
                    </Row>
                </Col>

                <Col span={6}>
                    <Row style={{ marginBottom: 5 }}>
                        <Select
                            showSearch
                            style={{ width: '100%' }}
                            disabled={!commission || (!subcommission && subcommsOfCurrentComm.length > 0) || commission.type < 2 || (subcommission && !subcommission.active)}
                            placeholder={'Scegli una sede di lavoro'}
                            onChange={onSelectPlaceOfWork}
                            value={placeOfWorkId && getItem(globalDataContext.allPlacesOfWork, placeOfWorkId).description}
                        >
                            {
                                globalDataContext.allPlacesOfWork.filter(filterPlacesOfWork).map((pow, idx) =>
                                    <Option
                                        key={'p' + idx}
                                        value={pow.description}
                                        activityid={id}
                                        placeofworkid={pow.id}
                                    >
                                        {pow.description}
                                    </Option>)
                            }
                        </Select>
                    </Row>
                    <Row justify="space-between">
                        <Col style={{ marginBottom: 5 }}>
                            <Tooltip title={renderNotes()}>
                                <Button
                                    disabled={id === 0 || saveAndNotesButtonsDisabled()}
                                    onClick={() => setShowNotesModal(true)}
                                >
                                    Note
                                </Button>
                            </Tooltip>
                        </Col>

                        <Col style={{ marginBottom: 5 }}>
                            <Button
                                icon={<SaveOutlined />}
                                type='primary'
                                disabled={saveAndNotesButtonsDisabled()}
                                onClick={() => onSaveActivity(id)}
                            />
                            <Button
                                icon={<DeleteOutlined />}
                                type='primary'
                                danger
                                disabled={(subcommission && !subcommission.active)}
                                onClick={() => onDeleteActivity(id)}
                            />
                        </Col>
                    </Row>
                </Col>
            </Row>

            <Modal
                centered
                title='Scrivi liberamente le note da aggiungere alla tua attività'
                open={showNotesModal}
                onOk={() => closeNotesModal(true)}
                onCancel={() => closeNotesModal(false)}
            >
                <Input.TextArea
                    maxLength={200}
                    value={notes}
                    onChange={e => onModifyNotes(id, e.target.value)}
                />
            </Modal>
        </div>
    );
};

export default Activity;